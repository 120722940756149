<template>
    <centered-layout :loading="pageLoading" loading-message="Checking Authorisation Code">
        <w-form-message :logo-path="clientLogo" :title="title" v-if="authorised || rejected">
            <p>You have {{authorised ? 'authorised' : 'rejected'}} access to {{clientName}}.</p>
            <p v-if="authorised">
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </p>
        </w-form-message>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="valid && !authorised">
            You have been invited to access {{clientName}}. Would you like to accept?
            <w-btn color="primary" @click="authorise" :loading="loading">
                Accept
            </w-btn>
            <w-btn @click="reject" :loading="loading">
                Reject
            </w-btn>
        </w-form-message>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="!valid">
            The authorisation code is invalid, please check you have entered it correctly or that
            it has not expired.
            <p>
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </p>
        </w-form-message>
    </centered-layout>
</template>

<script>
    import {LOGIN_PAGE} from '@/router/route-names'
    import {mapActions, mapGetters} from 'vuex'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WBtn from '@wf/components/WBtn'
    import WFormMessage from '@wf/components/WFormMessage'

    export default {
        name: 'AuthorisationPage',
        metaInfo () {
            const vm = this
            return {
                title: vm.dataLoading ? 'Loading Authorisation' : vm.title
            }
        },
        data: () => ({
            description: 'Set your new password below to complete activation.',
            errors: [],
            loginPage: LOGIN_PAGE,
            pageLoading: false,
            loading: false,
            authorised: false,
            rejected: false,
            valid: false
        }),
        components: {
            CenteredLayout,
            WBtn,
            WFormMessage
        },
        props: {
            userId: {
                type: String
            },
            code: {
                type: String
            }
        },
        methods: {
            ...mapActions('auth', ['checkClientAuthorisationCode', 'authoriseClient', 'rejectClient']),
            authorise () {
                const vm = this
                vm.loading = true
                vm.authoriseClient(vm.urlParams).then(() => {
                    vm.loading = false
                    vm.authorised = true
                }, () => {
                    vm.loading = false
                })
            },
            reject () {
                const vm = this
                vm.loading = true
                vm.rejectClient(vm.urlParams).then(() => {
                    vm.loading = false
                    vm.rejected = true
                }, () => {
                    vm.loading = false
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            title () {
                const vm = this
                const text = vm.valid ? 'Authorise Client' : 'Invalid Authorisation Code'
                return `${text} - ${vm.clientName}`
            },
            urlParams () {
                const vm = this
                return {
                    userId: vm.userId,
                    code: vm.code
                }
            },
            dataLoading () {
                const vm = this
                return vm.pageLoading || vm.loading
            }
        },
        created () {
            const vm = this
            vm.pageLoading = true
            vm.checkClientAuthorisationCode(vm.urlParams).then(() => {
                vm.valid = true
                vm.pageLoading = false
            }, (response) => {
                vm.errors = response.errors
                vm.pageLoading = false
            })
        }
    }
</script>
